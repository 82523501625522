import React, { useEffect, useState } from 'react';
import PaymentType from './PaymentType';
import { ReactComponent as Przelewy } from '../../../assets/przelewy.svg';
import { ReactComponent as Visa } from '../../../assets/visa.svg';
import { ReactComponent as Lock } from '../../../assets/lock.svg';
import GreenButton from '../../Global/GreenButton';
import Checkbox from '../../Global/Checkbox';
import { handleFetchInvoiceData } from '../../../services/profile.service';
import paymentService from '../../../services/payment.service';
import { useNavigate } from 'react-router-dom';

const Payment = ({ setSavedPaymentMethods, setShowPayment }) => {
  const [billingType, setBillingType] = useState('annual');
  const [chosenType, setChosenType] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [error, setError] = useState({});
  const [invoice, setInvoice] = useState({});
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleSwitchBilling = () => {
    setBillingType((prevState) => (prevState === 'annual' ? 'monthly' : 'annual'));
  };

  const handleOrderAndPayment = async () => {
    const orderData = {
      tariff: 'STARTER',
      activeDevicesNo: 1,
      isSubscription: false, //billingType === 'monthly',
    };

    try {
      const paymentResponse = await paymentService.createOrder(orderData);

      if (paymentResponse && !paymentResponse.error) {
        const { redirect_url } = paymentResponse;

        if (redirect_url) {
          setRedirectUrl(redirect_url);
          setIsModalOpen(true);
        } else {
          console.error('Brak adresu przekierowania do Przelewy24.');
        }
      } else {
        console.error('Wystąpił błąd podczas tworzenia zamówienia:', paymentResponse.errorContent);
      }
    } catch (error) {
      console.error('Wystąpił błąd:', error);
    }
  };

  const handleAddMethod = () => {
    console.log('chosenType:');
    console.log(chosenType);
    if (chosenType === '') {
      setError((prevState) => {
        return { ...prevState, type: 'Wybierz metodę płatności' };
      });
    } else if (!checkboxes.first) {
      setError((prevState) => {
        return { ...prevState, first: 'Zaakceptuj regulamin' };
      });
    } else if (!checkboxes.second) {
      setError((prevState) => {
        return { ...prevState, second: 'Zaakceptuj regulamin' };
      });
    } else if (!checkboxes.third) {
      setError((prevState) => {
        return { ...prevState, third: 'Zaakceptuj regulamin' };
      });
    } else if (chosenType === 'Karta płatnicza') {
      setSavedPaymentMethods((prevState) => {
        return [...prevState, { type: 'Visa', number: '**** **** **** 1234', expire: '10/28' }];
      });
      setShowPayment(false);
      // Wywołanie funkcji handleOrderAndPayment
      handleOrderAndPayment();
    } else {
      // Dla innych metod płatności
      handleOrderAndPayment();
    }
  };

  useEffect(() => {
    handleFetchInvoiceData()
      .then((response) => response.json())
      .then((data) => {
        setInvoice(data);
        console.log(data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className='payment-container'>
      <div className='billing-type'>
        <span className={`payment-text type ${billingType === 'annual' && 'active'}`}>Opłata roczna</span>
        <div
          className={`switch-container ${billingType === 'monthly' && 'active'}`}
          onClick={handleSwitchBilling}
        >
          <span className='switch-pill' />
        </div>
        <span className={`payment-text type ${billingType === 'monthly' && 'active'}`}>Subskrypcja miesięczna</span>
      </div>
      <div className='billing-info-container'>
        <div className='info-container'>
          <h2 className='payment-text payment-header'>Dane do faktury</h2>
          <div className='invoice-data'>
            {invoice ? (
              <>
                <span className='payment-text'>{invoice.name}</span>
                <span className='payment-text'>{invoice.address}</span>
                <span className='payment-text'>{invoice.postcode}</span>
                <span className='payment-text'>{invoice.city}</span>
                <span className='payment-text'>Polska</span>
                <span className='payment-text'>NIP: {invoice.tax_no}</span>
                <span
                  className='underline-text'
                  onClick={() => navigate('/myProfile?fromPayment=true')}
                >
                  Zmień dane do faktury
                </span>
              </>
            ) : (
              <span className='underline-text single'>Wprowadź dane do faktury</span>
            )}
          </div>
        </div>
        <div className='info-container'>
          <h2 className='payment-text payment-header'>Płatność</h2>
          <PaymentType
            chosenType={chosenType}
            error={error.type}
            setError={setError}
            setChosenType={setChosenType}
            text={'Przelew online'}
            type={<Przelewy />}
          />
          <PaymentType
            chosenType={chosenType}
            error={error.type}
            setError={setError}
            setChosenType={setChosenType}
            text={'Karta płatnicza'}
            type={<Visa />}
          />
          {error.type && <span className='error-text'>{error.type}</span>}
        </div>
        <div className='info-container'>
          <h2 className='payment-text payment-header'>Podsumowanie</h2>
          <div className='payment-sumup-container'>
            <div className='payment-charge'>
              <span className='payment-text'>Pakiet: STARTER</span>
              <div className='payment-details'>
                <span className='payment-text payment-detail'>Ilość urządzeń: 1</span>
                <span className='payment-text payment-detail'>Wiadomości: 20/dzień</span>
                <span className='payment-text payment-detail'>Archiwum wiadomości: 1 dzień</span>
              </div>
              <div className='payment-sum-container'>
                <span className='payment-sum'>600 zł</span>
              </div>
            </div>
            <div className='payment-charge'>
              <div className='inputs-container'>
                <Checkbox
                  checked={checkboxes.first}
                  error={error.first}
                  smaller
                  onChange={() => {
                    setCheckboxes((prevState) => {
                      return { ...prevState, first: !prevState.first };
                    });
                    setError((prevState) => {
                      return { ...prevState, first: '' };
                    });
                  }}
                />
                <span className='payment-text checkbox-text'>Zapoznałem sie z Regulaminem i Polityką Prywatności Mesango, ITP ITD</span>
              </div>
              <div className='inputs-container'>
                <Checkbox
                  checked={checkboxes.second}
                  error={error.second}
                  smaller
                  onChange={() => {
                    setCheckboxes((prevState) => {
                      return { ...prevState, second: !prevState.second };
                    });
                    setError((prevState) => {
                      return { ...prevState, second: '' };
                    });
                  }}
                />
                <span className='payment-text checkbox-text'>Zapoznałem sie z Regulaminem i Polityką Prywatności Mesango, ITP ITD</span>
              </div>
              <div className='inputs-container'>
                <Checkbox
                  checked={checkboxes.third}
                  error={error.third}
                  smaller
                  onChange={() => {
                    setCheckboxes((prevState) => {
                      return { ...prevState, third: !prevState.third };
                    });
                    setError((prevState) => {
                      return { ...prevState, third: '' };
                    });
                  }}
                />
                <span className='payment-text checkbox-text'>Zapoznałem sie z Regulaminem i Polityką Prywatności Mesango, ITP ITD</span>
              </div>
              {error.first && <span className='error-text'>{error.first}</span>}
              {error.second && <span className='error-text'>{error.second}</span>}
              {error.third && <span className='error-text'>{error.third}</span>}
            </div>
            <div className='payment-charge'>
              <GreenButton
                noMargin
                fullWidth
                handleClick={handleAddMethod}
              >
                <Lock style={{ marginRight: '20px' }} /> Zamawiam i płacę
              </GreenButton>
            </div>
          </div>
        </div>
      </div>
      {/* Modal z linkiem do płatności */}
      {isModalOpen && (
        <div className='modal-overlay'>
          <div className='modal'>
            <h2>Link do płatności</h2>
            <p>
              Oto link do płatności w Przelewy24:
              <br />
              <a
                href={redirectUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {redirectUrl}
              </a>
            </p>
            <button onClick={() => setIsModalOpen(false)}>Zamknij</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
